import React from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";;

function LoadingIndicator() {
    const styles = StyleSheet.create({
        container: {
          flex: 1,
          justifyContent: "center"
        },
        horizontal: {
          flexDirection: "row",
          justifyContent: "space-around",
          padding: 10
        }
      });

    return (
        <View style={[styles.container, styles.horizontal]}>
        <ActivityIndicator size="large" color="#282156" />
        </View>
    )
}

export default LoadingIndicator;
